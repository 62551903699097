/** @see https://www.gatsbyjs.org/docs/browser-apis/ */
import React from "react";
import RootLayout from "./src/components/element/layout/RootLayout";

import "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

export const wrapRootElement = ({ element }) => <RootLayout>{element}</RootLayout>;
