import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../element/graphical/Logo";
import theme, { oo } from "../../element/theme";
import NavigationDesktop from "./NavigationDesktop";
import NavigationMobile from "./NavigationMobile";
import CtaButton from "./CtaButton";
import CtaLoggedInButton from "./CtaLoggedInButton";
import { AuthContext } from "../../auth/AuthContextProvider";

const header = {
  width: "100%",
  backgroundColor: oo.oberionBlueBackground,
  color: theme.palette.primary.contrastText,
  padding: "0.5rem 1rem",
};
const headerContent = {
  maxWidth: `${oo.contentWidth}rem`,
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
};

const useStyles = makeStyles(theme => ({
  desktopNav: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  mobileNav: {
    display: "flex",
    marginLeft: "0.7rem",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const Header = ({ showMinimalHeader }) => {
  const classes = useStyles();

  let ctaButton;
  if (showMinimalHeader) ctaButton = null;
  else if (useContext(AuthContext).isLoggedIn()) ctaButton = <CtaLoggedInButton />;
  else ctaButton = <CtaButton />;


  return (
    <header style={header}>
      <div style={headerContent}>

        <Logo />

        <div className={classes.grow} />

        <nav className={classes.desktopNav}>
          <NavigationDesktop />
        </nav>
        <div className={classes.mobileNav}>
          <NavigationMobile />
        </div>
        {ctaButton}

      </div>
    </header>
  );
};
Header.defaultProps = {
  showMinimalHeader: false,
};
Header.propTypes = {
  showMinimalHeader: PropTypes.bool,
};
export default Header;
