/**
 * Enum for all possible routes.
 *
 * For nested routes:
 * Only "settings/" may lead to wrong routes like "/login/settings/".
 * => Always use relative url from root.
 */
export default {
  baseUrl: "https://oberion.io",
  home: {
    caption: "Home",
    to: "/",
  },
  about: {
    caption: "About",
    to: "/about/",
  },
  blog: {
    caption: "Blog",
    to: "/blog/",
  },
  createWebsite: {
    caption: "Create Website",
    to: "/create-website/",
  },
  newsletter: {
    caption: "Newsletter",
    to: "/#newsletter",
  },
  legal: {
    caption: "Legal Notice",
    to: "/legal/",
  },
  login: {
    caption: "Login",
    to: "/login/",
  },
  preview: {
    caption: "Website Preview",
    to: "/preview/",
  },
  pricing: {
    caption: "Pricing",
    to: "/#pricing",
  },
  privacy: {
    caption: "Privacy Policy",
    to: "/privacy/",
  },
  rss: {
    caption: "RSS",
    to: "/rss.xml",
  },
  terms: {
    caption: "Terms of Service",
    to: "/terms/",
  },
  templates: {
    caption: "Templates",
    to: "/templates/",
  },
  thanks: {
    caption: "Thanks",
    to: "/thanks/",
  },
  user: {
    caption: "User",
    to: "/user/",
    sites: {
      create: {
        caption: "Create Website",
        to: "/user/sites/create/",
      },
      deploy: {
        caption: "Deploy Website",
        to: "/user/sites/deploy/",
      },
      domain: {
        caption: "Add a domain for your website",
        to: "/user/sites/domain/",
      },
    },
    settings: {
      caption: "Settings",
      to: "/user/settings/",
    },
  }
};