// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-dev-jsx": () => import("./../../../src/pages/dev.jsx" /* webpackChunkName: "component---src-pages-dev-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-templates-index-jsx": () => import("./../../../src/pages/templates/index.jsx" /* webpackChunkName: "component---src-pages-templates-index-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../../../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-pages-user-index-jsx": () => import("./../../../src/pages/user/index.jsx" /* webpackChunkName: "component---src-pages-user-index-jsx" */),
  "component---src-pages-user-settings-jsx": () => import("./../../../src/pages/user/settings.jsx" /* webpackChunkName: "component---src-pages-user-settings-jsx" */),
  "component---src-pages-user-sites-create-jsx": () => import("./../../../src/pages/user/sites/create.jsx" /* webpackChunkName: "component---src-pages-user-sites-create-jsx" */),
  "component---src-pages-user-sites-deploy-jsx": () => import("./../../../src/pages/user/sites/deploy.jsx" /* webpackChunkName: "component---src-pages-user-sites-deploy-jsx" */),
  "component---src-pages-user-sites-domain-jsx": () => import("./../../../src/pages/user/sites/domain.jsx" /* webpackChunkName: "component---src-pages-user-sites-domain-jsx" */),
  "component---src-pages-user-sites-index-jsx": () => import("./../../../src/pages/user/sites/index.jsx" /* webpackChunkName: "component---src-pages-user-sites-index-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/BlogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */)
}

