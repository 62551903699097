import React, { useContext } from "react";
import { Avatar, Button, Menu, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { navigate } from "gatsby";
import { AuthContext } from "../../auth/AuthContextProvider";
import routes from "../routes";
import { oo } from "../../element/theme";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: oo.textColorOnDark,
    color: "inherit",
    height: "2.2rem",
    width: "auto",
  },
  avatarIcon: {
    height: "2.2rem",
    width: "auto",
  },
  buttonAvatar: {
    padding: "0",
    minWidth: "initial",
  },
});

const CtaLoggedInButton = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authContext = useContext(AuthContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    handleClose();
    navigate(routes.user.settings.to);
  };
  const handleWebsite = () => {
    handleClose();
    navigate(routes.user.sites.create.to);
  };

  const handleLogout = () => {
    handleClose();
    authContext.logUserOut();
    navigate(routes.login.to);
  };

  return (
    <>
      <Button onClick={handleClick} className={classes.buttonAvatar}
              aria-controls="user-dropdown" aria-haspopup="true">
        <Avatar className={classes.avatar} component="div">
          <AccountCircleIcon className={classes.avatarIcon} />
        </Avatar>
      </Button>

      <Menu
        id="user-dropdown"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem button onClick={handleWebsite}>{routes.user.sites.create.caption}</MenuItem>
        <MenuItem button onClick={handleSettings}>{routes.user.settings.caption}</MenuItem>
        <MenuItem button onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
export default CtaLoggedInButton;
