import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";


export const oo = {
  /** Oberion branded theme constants **/
  contentWidth: 70,
  contentWidthNarrow: 32,
  mobileMinWidth: 20,
  textWidth: 44,
  verticalSpacing: 3,
  horizontalSpacing: 0.5,
  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",

  /** --- oo color palette --- */
  oberionBlue: "#312782",
  oberionBlueLight: "#6350b2",
  oberionBlueDark: "#000054",
  oberionBluePaper: "#190555",
  oberionBlueBackground: "#00002c",
  oberionTeal: "#03dac4",
  oberionTealLight: "#66fff7",
  oberionTealDark: "#00B2CC",
  linkViolet: "#4600C8",
  footerBackground: "#001935",
  paperBackgroundLight: "#ffffff",
  textColorOnDark: "#ffffff",
  textColorOnLight: "#1f1f1f",
  textErrorLight: "#e57373",
  textErrorMain: "#f44336",
  textErrorDark: "#d32f2f",
  textGrey: "#5a5a5a",
  gatsbyPurple: "#663399",
  stackOverflowDarkThemeGreen: "#13552F",

  /** --- Oberion branded color palette --- */
  oberionBlue1: "#312782", // => oberionBlue
  oberionBlue2: "#4830B2",
  oberionBlue3: "#6854E5",
  oberionBlue4: "#877AFF",
  oberionBlue5: "#A2A0FF",
  grey1: "#1e1e1e",
  grey2: "#3c3c3c",
  grey3: "#5a5a5a", // => textGrey
  grey4: "#787878",
  grey5: "#969696",
  grey6: "#b4b4b4",
  grey7: "#d2d2d2",
  grey8: "#f0f0f0",
  grey9: "#F7F7F7", // body => background-color
  white: "#FFFFFF",
  purple1: "#430996",
  purple2: "#4600C8",
  purple3: "#823FFC",
  purple4: "#996BFF",
  purple5: "#DCCCFF",
  blue1: "#008BB3",
  blue2: "#00B2CC",
  blue3: "#0CDFF2",
  blue4: "#6EFFFF",
  blue5: "#BFFFFF",
  teal1: "#008E76",
  teal2: "#00BFA5",
  teal3: "#00E5C6",
  teal4: "#5DF2D6",
  teal5: "#99FFEC",
  green1: "#00993F",
  green2: "#00C853",
  green3: "#50DC4B",
  green4: "#C3F528",
  green5: "#DEFF7C",
  yellow1: "#F99A00",
  yellow2: "#FFB43D",
  yellow3: "#FFDD00",
  yellow4: "#FFE859",
  yellow5: "#FFF199",
  red1: "#AF0000",
  red2: "#DC1E00",
  red3: "#F15A24",
  red4: "#FF8259",
  red5: "#FFAB9E",

  /** brand colors */
  discordBlue: "#7289da",
  facebookBlue: "#395693",
  instagramBlack: "#000000",
  redditOrange: "#ff4500",
  twitterBlue: "#1da1f2",
  youTubeRed: "#f60000",
  gitHubBlack: "#000000",
  twitchPurple: "#8c44f7",

  /** GitHub colors */
  gitHubCheckGreen: "#2CBE4E",
  gitHubCheckRed: "#C92636",
  gitHubCheckGrey: "#6A737D",
  gitHubCheckYellow: "#DBAB09",
  gitHubSubTitle: "#586069",
};


/** Properties to customize Material UI Typography. */
export const pxToRem = value => `${value / 16}rem`;
export const remToPx = value => `${value * 16}px`;
export const fontWeightLight = 300;
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;


/** --- Material UI theme objects ---
 * Overriding styles: @see https://github.com/mui-org/material-ui/issues/11244#issuecomment-386792253
 */
const createdBreakpoints = createBreakpoints({});
const themeObject = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: "light",
    background: {
      paper: oo.white,
      default: oo.grey9,
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.46)", // MUI-standard: rgba(0, 0, 0, 0.26)
      disabledBackground: "rgba(0, 0, 0, 0.12)", // MUI-standard: rgba(0, 0, 0, 0.12)
    },
    primary: {
      light: oo.oberionBlueLight,
      main: oo.oberionBlue,
      dark: oo.oberionBlueDark,
      contrastText: oo.textColorOnDark,
    },
    secondary: {
      light: oo.oberionTealLight,
      main: oo.oberionTeal,
      dark: oo.oberionTealDark,
      contrastText: oo.textColorOnLight,
    },
    error: {
      light: oo.textErrorLight,
      main: oo.textErrorMain,
      dark: oo.textErrorDark,
      contrastText: oo.textColorOnLight,
    },
    text: {
      // primary: "rgba(0, 0, 0, 0.87)", // MUI standard
      // primary: "#ffffff",
      primary: oo.textColorOnLight,
      // secondary: "rgba(0, 0, 0, 0.54)", // MUI standard
      secondary: "#03dac4", // controls footer/newsletter/checkbox + textfield label
      disabled: "rgba(0, 0, 0, 0.38)", // MUI standard
      // disabled: "#b4b4b4",
      // hint: "rgba(0, 0, 0, 0.38)", // MUI standard
      hint: "#00B2CC", // controls footer/link:hover in footer elements
    },
  },
  overrides: {
    MuiTypography: {
      /**
       * # Responsive Typography:
       * @see https://stackoverflow.com/questions/52472372/responsive-typography-in-material-ui
       * # Material Design specification:
       * @see https://material.io/design/typography/the-type-system.html#type-scale
       * # MUI default values:
       * @see node_modules\@material-ui\core\styles\createTypography.js
       * - letterSpacing: space between letters in a word
       */
      h1: {
        fontWeight: fontWeightLight,
        fontSize: pxToRem(64),  // 96 customized
        [createdBreakpoints.down("xs")]: { fontSize: pxToRem(48) }, // 96 * 0.5
        lineHeight: 1,
        letterSpacing: -1.5,
      },
      h2: {
        fontWeight: fontWeightLight,
        fontSize: pxToRem(48),  // 60 customized
        [createdBreakpoints.down("xs")]: { fontSize: pxToRem(36) }, // 48 * 0.75
        lineHeight: 1,
        letterSpacing: -0.5,
      },
      h3: {
        fontWeight: fontWeightRegular,
        fontSize: pxToRem(36),  // 48 customized
        [createdBreakpoints.down("xs")]: { fontSize: pxToRem(30) }, // 34 * 0.75
        lineHeight: 1.04,
        letterSpacing: 0,
      },
      h4: {
        fontWeight: fontWeightRegular,
        fontSize: pxToRem(28),  // 34 customized
        [createdBreakpoints.down("xs")]: { fontSize: pxToRem(27) },
        lineHeight: 1.17,
        letterSpacing: 0.25,
      },
      h5: {
        fontWeight: fontWeightMedium,  // fontWeightRegular customized
        fontSize: pxToRem(24),
        lineHeight: 1.33,
        letterSpacing: 0,
      },
      h6: {
        fontWeight: fontWeightMedium,
        fontSize: pxToRem(21),
        lineHeight: 1.6,
        letterSpacing: 0.15,
      },
      subtitle1: {
        fontWeight: fontWeightRegular,
        fontSize: pxToRem(18),
        lineHeight: 1.75,
        letterSpacing: 0.15,
      },
      subtitle2: {
        fontWeight: fontWeightMedium,  // fontWeightRegular customized
        fontSize: pxToRem(18),   // 16 customized
        lineHeight: 1.57,
        letterSpacing: 0.1,
      },
      body1: {
        fontWeight: fontWeightRegular,
        fontSize: pxToRem(16),
        lineHeight: 1.7,               // 1.5 customized
        letterSpacing: 0.15,
      },
      body2: {
        fontWeight: fontWeightMedium,  // fontWeightRegular customized
        fontSize: pxToRem(16),   // 14 customized
        lineHeight: 1.6,
        letterSpacing: 0.15,
      },
      button: {
        fontWeight: fontWeightMedium,
        fontSize: pxToRem(16),  // 14 customized
        lineHeight: 1.75,
        letterSpacing: 0.4,
      },
      caption: {
        fontWeight: fontWeightRegular,
        fontSize: pxToRem(14),  // 12 customized
        lineHeight: 1.66,
        letterSpacing: 0.4,
      },
      overline: {
        fontWeight: fontWeightRegular,
        fontSize: pxToRem(14),  // 12 customized
        lineHeight: 2.66,
        letterSpacing: 1,
      },
    },
  },
};
const ooTheme = createMuiTheme(themeObject);
export default ooTheme;
