import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import routes from "../routes";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles({
  cta: {
    padding: "0.5rem 1rem",
    textTransform: "none",
  },
});

const CtaButton = () => {
  const classes = useStyles();

  return (
    <ButtonGroup disableElevation color="secondary" variant="outlined">
      <Button component={GatsbyLink} to={routes.login.to}
              startIcon={<AccountCircleIcon />}
              className={classes.cta}
      >
        {routes.login.caption}
      </Button>
      <Button component={GatsbyLink} to={routes.login.to + "#signup"}
              className={classes.cta}
      >
        Sign Up
      </Button>
    </ButtonGroup>
  );
};
export default CtaButton;
