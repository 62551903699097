import React from "react";
import PropTypes from "prop-types";
import { getFirebase } from "./util/firebase-instance";
import ky from "ky";
import endpoint from "../rest/endpoint";
import { logError } from "../element/error/sentry";

export const AuthContext = React.createContext(false);

const initialState = {
  authUser: null,
  isNewUser: false,
  firebase: null,
  redirectTo: null,
  game: null,
};

export class AuthContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  /**
   * Firebase SDK expects to be run in the browser.
   * Because of Gatsby SSR, Firebase must only be initialized during rendering in the browser.
   * Lazy initialization in `componentDidMount()` accomplishes this.
   */
  componentDidMount() {
    if (!this.state.firebase) {
      const lazyApp = import("firebase/app");
      const lazyAuth = import("firebase/auth");
      const lazyFirestore = import("firebase/firestore");
      const lazyStorage = import("firebase/storage");

      /** only start initializing when firebase SDK is ready */
      Promise.all([lazyApp, lazyAuth, lazyFirestore, lazyStorage]).then(([firebase]) => {

        /** initialize firebase */
        this.setState({ firebase: getFirebase(firebase) });

        /** set listener whether user is signed in */
        this.listenerOnAuthStateChanged = getFirebase(firebase).auth().onAuthStateChanged(
          authUser => {
            if (authUser) {
              this.setState({ authUser });
            } else {
              this.setState({ authUser: null });
            }
          },
        );
        this.listenerOnIdTokenChanged = getFirebase(firebase).auth().onIdTokenChanged(
          async authUser => {
            if (!this.state.isNewUser && authUser) {
              try {
                const idToken = await getFirebase(firebase).auth().currentUser.getIdToken();
                const requestOptions = { json: { idToken: idToken } };
                await ky.patch(endpoint.userChangedToken, requestOptions);
              } catch (error) {
                logError("Failed to send changed token", { uid: authUser.uid });
              }
            }
          },
        );
      });
    }
  }

  /** Clean up. Remove listeners. */
  componentWillUnmount() {
    this.listenerOnAuthStateChanged && this.listenerOnAuthStateChanged();
    this.listenerOnIdTokenChanged && this.listenerOnIdTokenChanged();
  }

  getContextState = () => {
    return this.state;
  };
  getFirebase = () => {
    return this.state.firebase;
  };
  getFirestoreDb = () => {
    return this.state.firebase.firestore();
  };
  getStorage = () => {
    return this.state.firebase.storage();
  };
  getUser = () => {
    return this.state.authUser;
  };

  // logUserIn = () => handled by dependency: "react-firebaseui/StyledFirebaseAuth"
  logUserOut = () => {
    this.state.firebase.auth().signOut();
  };
  isLoggedIn = () => {
    return !!this.state.authUser;
  };

  isNewUser = () => {
    return this.state.isNewUser;
  };
  setNewUser = (isNewUser) => {
    this.setState({ isNewUser: isNewUser });
  };

  getRedirectTo = () => {
    return this.state.redirectTo;
  };
  setRedirectTo = (path) => {
    if (path !== this.state.redirectTo && this.isLoggedIn()) this.setState({ redirectTo: path });
  };

  getGame = () => {
    return this.state.game;
  };
  setGame = (game) => {
    this.setState({ game: game });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          getContextState: this.getContextState,
          getFirebase: this.getFirebase,
          getFirestoreDb: this.getFirestoreDb,
          getStorage: this.getStorage,
          getUser: this.getUser,
          logUserOut: this.logUserOut,
          isLoggedIn: this.isLoggedIn,
          isNewUser: this.isNewUser,
          setNewUser: this.setNewUser,
          getRedirectTo: this.getRedirectTo,
          setRedirectTo: this.setRedirectTo,
          getGame: this.getGame,
          setGame: this.setGame,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

AuthContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
