import { createMuiTheme } from "@material-ui/core/styles";

const themeObject = {
  palette: {
    type: "dark",
    primary: {
      light: "#6350b2",
      main: "#312782",
      dark: "#000054",
      contrastText: "#fff",
    },
    secondary: {
      light: "#66fff7",
      main: "#03dac4",
      dark: "#00B2CC",
      contrastText: "#000",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      // primary: "rgba(0, 0, 0, 0.87)", // MUI standard
      primary: "#ffffff",
      // primary: oo.textColorOnLight,
      // secondary: "rgba(0, 0, 0, 0.54)", // MUI standard
      secondary: "#03dac4", // controls footer/newsletter/checkbox + textfield label
      // disabled: "rgba(0, 0, 0, 0.38)", // MUI standard
      disabled: "#b4b4b4",
      // hint: "rgba(0, 0, 0, 0.38)", // MUI standard
      hint: "#00B2CC", // controls footer/link:hover in footer elements
    },
  },
  overrides: {},
};
export const muiDarkTheme = createMuiTheme(themeObject);
