import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Drawer, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import MenuIcon from "@material-ui/icons/Menu";
import navigationElements from "./navigation-elements";
import { oo } from "../../element/theme";

const styles = {
  listNav: {
    width: "auto",
    padding: "1rem 5rem",
    color: oo.textColorOnDark,
    backgroundColor: oo.oberionBlueBackground,
  },
  gatsbyLink: {
    textDecoration: "none",
    color: "inherit",
  },
  menuButton: {
    height: "2.2rem",
    width: "auto",
    marginRight: "0.6rem",
  },
  menuIcon: {
    height: "2.2rem",
    width: "auto",
  },
};

class NavigationMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
    };
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes } = this.props;
    const { top } = this.state;

    const fullList = (
      <nav className={classes.listNav}>
        <List component="ul">
          {navigationElements.map((element) => {
            return (
              <ListItem component="li" button divider key={element.caption}>
                <GatsbyLink to={element.to} className={classes.gatsbyLink}>
                  <ListItemText primary={element.caption} />
                </GatsbyLink>
              </ListItem>
            );
          })}
        </List>
      </nav>
    );

    return (
      <>
        <IconButton color="inherit" className={classes.menuButton}
                    aria-label="Open drawer"
                    onClick={this.toggleDrawer("top", true)}>
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        <Drawer anchor="top" open={top} onClose={this.toggleDrawer("top", false)}>
          <div tabIndex={0}
               role="button"
               onClick={this.toggleDrawer("top", false)}
               onKeyDown={this.toggleDrawer("top", false)}>
            {fullList}
          </div>
        </Drawer>
      </>
    );
  }
}

NavigationMobile.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(NavigationMobile);