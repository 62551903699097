const antennaOrigin = process.env.GATSBY_OBERION_ORIGIN_ANTENNA;

// @formatter:off
export default {
  ping:                     `${antennaOrigin}/ping`, // implemented by probot
  api:                      `${antennaOrigin}/api/`, // needed with trailing slash for limiter
  apiPing:                  `${antennaOrigin}/api/ping`,
  user:                     `${antennaOrigin}/api/user`,
  userApiKey:               `${antennaOrigin}/api/user/api-key`,
  userChangedToken:         `${antennaOrigin}/api/user/changed-token`,
  userWebsiteData:          `${antennaOrigin}/api/user/website-data`,
  ghInstallationAuthorize:  `${antennaOrigin}/api/github/installation/authorize`,
  previewWebsiteData:       `${antennaOrigin}/api/preview/website-data`,
};