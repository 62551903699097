import styled from "styled-components";

export const ConciseUl = styled.ul`
  padding-left: 0;
  list-style-type: square;
`;

export const ConciseLegalNotice = styled.div`
  margin: 1rem auto 0 auto;
  max-width: 30rem;
  text-align: left;
  padding-left: 1.5rem;
`;