import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import logoWhite from "../../../res/branded-oberion/logo-white.OberionIO.min.svg";
import logoBlue from "../../../res/branded-oberion/logo-blue.OberionIO.min.svg";
import routes from "../../navigation/routes";
import PropTypes from "prop-types";

const Image = styled.img`
  box-sizing: border-box;
  display: block;
  height: 2.25rem;
  padding: 0.25rem;
  width: auto;
`;

const TransitionLink = styled.a`
  opacity: 1;
  transition: .5s ease;
  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.7;
  }
`;

const Logo = ({ color }) => {
  let src;
  color ? src = logoBlue : src = logoWhite;
  return (
    <TransitionLink as={Link} to={routes.home.to}>
      <Image src={src} alt="Oberion Logo" />
    </TransitionLink>
  );
};
Logo.defaultProps = {
  color: false,
};
Logo.propTypes = {
  color: PropTypes.bool,
};
export default Logo;
