import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CHANNELS } from "./SocialMediaButton";
import { oo } from "../theme";

const DefaultIcon = styled.img`
    box-sizing: border-box;
    display: block;
    height: 1.9rem;
    width: auto;
    margin: 0 auto;
`;
const TwitterIcon = styled(DefaultIcon)` height: 1.87rem; `;
const DiscordIcon = styled(DefaultIcon)` 
  height: 2.25rem;
  margin: 0 auto -0.12rem auto;
`;
const RSSIcon = styled(DefaultIcon)` filter: invert(100%); `;
const MediumIcon = styled(DefaultIcon)`
  border: 1px solid ${oo.grey3}; 
  box-sizing: content-box 
`;

function SocialMediaIcon({ icon, caption }) {
  let CustomizedIcon = DefaultIcon;

  switch (caption) {
    case CHANNELS.TWITTER.name:
      CustomizedIcon = TwitterIcon;
      break;
    case CHANNELS.DISCORD.name:
      CustomizedIcon = DiscordIcon;
      break;
    case CHANNELS.RSS.name:
      CustomizedIcon = RSSIcon;
      break;
    case CHANNELS.MEDIUM.name:
      CustomizedIcon = MediumIcon;
      break;
    default:
      break;
  }

  return (
    <CustomizedIcon
      src={icon}
      alt={caption}
    />
  );
}
SocialMediaIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};
export default SocialMediaIcon;
