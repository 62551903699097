import React from "react";
import { Button } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import routes from "../../navigation/routes";
import LayoutCosmos from "../layout/LayoutCosmos";

const seo = {
  title: "400 Bad Request - Oberion",
  description: null,
};
const heading = "400 Bad Request";
const subheading = [
  "Your client issued a malformed or illegal request.",
  "We'll be looking into it.",
];
const buttons = [
  <Button component={GatsbyLink}
          to={routes.home.to}
          variant="contained"
          color="secondary"
          fullWidth>
    Home
  </Button>,
];

const BadRequest400 = () => (
  <LayoutCosmos seo={seo} heading={heading} subheading={subheading} buttons={buttons} />
);
export default BadRequest400;
