import { captureException, captureMessage, setExtras } from "@sentry/browser";

const isDevMode = process.env.NODE_ENV !== "production";

const logConsole = (error, extras) => {
  if (isDevMode) console.error("Caught error:", error, "Extras:", extras);
};

export const logError = (error, extras) => {
  logConsole(error, extras);
  if (extras) setExtras(extras);
  captureException(error);
};

export const logMessage = (message, extras) => {
  logConsole(message, extras);
  if (extras) setExtras(extras);
  captureMessage(message);
};
