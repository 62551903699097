import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Header from "../../navigation/header/Header";
import Footer from "../../navigation/footer/Footer";

const PageContainer = styled.div`
  height: 100%;
  min-height: calc(100vh);
  
  display: flex; 
  flex-direction: column;
`;

/**
 * Fundamental layout which provides Header and Footer in a column style.
 */
const Layout = ({ children, showNewsletter, showMinimalHeader }) => (
  <PageContainer>
    <Header showMinimalHeader={showMinimalHeader} />

    {children}

    <Footer showNewsletter={showNewsletter} />
  </PageContainer>
);
Layout.defaultProps = {
  showNewsletter: false,
  showMinimalHeader: false,
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showNewsletter: PropTypes.bool,
  showMinimalHeader: PropTypes.bool,
};
export default Layout;
