import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../theme";
import "../styles.css";
// import "./prism-dracula.css"; // more styles at https://github.com/PrismJS/prism-themes
import "./prism-ghcolors.css"; // more styles at https://github.com/PrismJS/prism-themes
import ErrorBoundary from "../error/ErrorBoundary";
import favicon from "../../../../static/logo.OberionIO.min.svg";
import { AuthContextProvider } from "../../auth/AuthContextProvider";

export default function RootLayout({ children }) {
  return (
    <>
      <Helmet>
        <meta name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthContextProvider>
            {children}
          </AuthContextProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}
RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
