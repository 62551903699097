import routes from "../routes";

export default [
  {
    caption: routes.home.caption,
    to: routes.home.to,
    displayInAppBar: false,
  },
  {
    caption: routes.blog.caption,
    to: routes.blog.to,
    displayInAppBar: true,
  },
  {
    caption: routes.pricing.caption,
    to: routes.pricing.to,
    displayInAppBar: true,
  },
  {
    caption: routes.templates.caption,
    to: routes.templates.to,
    displayInAppBar: true,
  },
];
