import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import OberionLandingPageHeader from "../../../res/branded-oberion/cosmos.OberionIO.min.svg";
import { greaterOrEqualThan } from "../mediaQuery";
import SEO from "../SEO";
import Layout from "./Layout";
import { oo } from "../theme";

const BackgroundContainer = styled.main`
  color: ${oo.textColorOnDark};
  background-color: #00002c;
  background-image: url(${OberionLandingPageHeader});
  background-position: top center;
  background-size: cover;
  ${greaterOrEqualThan.sm`background-size: auto;`};
  @media (min-height: 1000px) and (min-width: 600px) {
    background-size: cover;
  }
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;
const CenteredContent = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${oo.verticalSpacing}rem ${oo.horizontalSpacing}rem;
`;
const HelpfulLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 8rem;
`;
const HelpfulLinksElements = styled.li`
  margin: 0 0 0.5rem 0;
`;

const LayoutCosmos = ({ seo, heading, subheading, buttons }) => (
  <Layout showNewsletter showMinimalHeader>
    <SEO {...seo} />
    <BackgroundContainer>
      <CenteredContent>
        <Typography variant="h2" gutterBottom>{heading}</Typography>
        {subheading.map((element, index) => (
          <Typography variant="body2" gutterBottom key={index}>
            {element}
          </Typography>
        ))}
        <HelpfulLinks>
          {buttons.map((button, index) => (
            <HelpfulLinksElements key={index}>
              {button}
            </HelpfulLinksElements>
          ))}
        </HelpfulLinks>
      </CenteredContent>
    </BackgroundContainer>
  </Layout>
);
LayoutCosmos.propTypes = {
  seo: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.array.isRequired,
  buttons: PropTypes.array.isRequired,
};
export default LayoutCosmos;
