import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import navigationElements from "./navigation-elements";
import ooTheme from "../../element/theme";

const useStyles = makeStyles({
  button: {
    color: ooTheme.palette.primary.contrastText,
    minWidth: "8rem",
    padding: "1rem 1rem",
    "&:hover": {
      backgroundColor: ooTheme.palette.primary.main,
    },
  },
});

const NavigationDesktop = () => {
  const classes = useStyles();

  return (
    <>
      {navigationElements.map((element) => {
        if (element.displayInAppBar) {
          return (
            <Button component={GatsbyLink} to={element.to} key={element.caption}
                    className={classes.button} size="large">
              {element.caption}
            </Button>
          );
        }
        return null;
      })}
    </>
  );
};
export default NavigationDesktop;