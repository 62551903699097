import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Logo from "../../element/graphical/Logo";
import theme, { oo } from "../../element/theme";
import { InternalRoute } from "../InternalRoute";
import routes from "../routes";
import Newsletter from "./Newsletter";
import SocialMediaButton, { CHANNELS } from "../../element/social-media/SocialMediaButton";
import { A_REL } from "../ExternalLink";


const copyrightTxt = [`\u00A9 ${new Date().getFullYear()} Oberion by `, "TJU Software UG"];
const gatsbyTxt = ["Built with ", "GatsbyJS", "https://www.gatsbyjs.org", " 💜"];

const StickyBottomFooter = styled.footer`
  margin-top: auto;
  padding: 0 0.5rem; /* https://material-ui.com/components/grid/#negative-margin */
  background-color: ${oo.oberionBlueBackground};
`;
const FooterGridContainer = styled(Grid)`
  background: ${oo.footerBackground};
  padding: 3rem 1rem;
  flex-grow: 1;
`;
const LogoContainer = styled.div`
  justify-content: center;
  display: flex;
`;
const LegalTextDiv = styled.div`
  color: ${oo.grey7};
  text-align: center;
  margin-top: 0.5rem;
`;
const FooterUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;
const FooterLiIcon = styled.li`
  display: inline-block;
  margin: 0 0;
`;
const FooterLi = styled.li`
  display: inline-block;
  margin: 0 1rem;
`;
const FooterLink = styled.a`
  text-decoration: underline;
  text-decoration-style: dotted;
  color: inherit;
    :hover {
    color: ${theme.palette.text.hint};
  }
  :active {
    color: ${theme.palette.text.hint};
  }
`;

const Footer = ({ showNewsletter }) => {
  let newsletter;
  if (showNewsletter) {
    newsletter = <Newsletter />;
  }

  return (
    <StickyBottomFooter>
      {newsletter}

      <FooterGridContainer container spacing={2}>

        <Grid item xs={12}>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Grid>

        <Grid component="nav" item xs={12}>
          <FooterUl>
            <FooterLiIcon>
              <SocialMediaButton channel={CHANNELS.TWITTER} />
            </FooterLiIcon>
            <FooterLiIcon>
              <SocialMediaButton channel={CHANNELS.RSS} />
            </FooterLiIcon>
            <FooterLiIcon>
              <SocialMediaButton channel={CHANNELS.MEDIUM} />
            </FooterLiIcon>
            {/*<FooterLiIcon>*/}
            {/*  <SocialMediaButton channel={CHANNELS.REDDIT} />*/}
            {/*</FooterLiIcon>*/}
            {/*<FooterLiIcon>*/}
            {/*  <SocialMediaButton channel={CHANNELS.DISCORD} />*/}
            {/*</FooterLiIcon>*/}
            {/*<FooterLiIcon>*/}
            {/*  <SocialMediaButton channel={CHANNELS.PATREON} />*/}
            {/*</FooterLiIcon>*/}
          </FooterUl>
        </Grid>

        <Grid component="nav" item xs={12}>
          <FooterUl>
            <FooterLi>
              <InternalRoute caption={routes.about.caption} to={routes.about.to}
                             variant="subtitle1" />
            </FooterLi>
            <FooterLi>
              <InternalRoute caption={routes.blog.caption} to={routes.blog.to}
                             variant="subtitle1" />
            </FooterLi>
            <FooterLi>
              <InternalRoute caption={routes.legal.caption} to={routes.legal.to}
                             variant="subtitle1" />
            </FooterLi>
            <FooterLi>
              <InternalRoute caption={routes.privacy.caption} to={routes.privacy.to}
                             variant="subtitle1" />
            </FooterLi>
            <FooterLi>
              <InternalRoute caption={routes.terms.caption} to={routes.terms.to}
                             variant="subtitle1" />
            </FooterLi>
          </FooterUl>
        </Grid>

        <Grid item xs={12}>
          <LegalTextDiv>
            <Typography variant="caption" style={{ display: "block", marginBottom: "0.25rem" }}>
              {copyrightTxt[0]}
              <FooterLink as={GatsbyLink} to={routes.about.to}>{copyrightTxt[1]}</FooterLink>
            </Typography>
            <Typography variant="caption">
              {gatsbyTxt[0]}
              <FooterLink href={gatsbyTxt[2]} rel={A_REL.ENDORSE}>{gatsbyTxt[1]}</FooterLink>
              {gatsbyTxt[3]}
            </Typography>

          </LegalTextDiv>
        </Grid>

      </FooterGridContainer>
    </StickyBottomFooter>
  );
};
Footer.propTypes = {
  hideNewsletter: PropTypes.bool,
};
Footer.defaultProps = {
  hideNewsletter: false,
};
export default Footer;
export { FooterLink };
