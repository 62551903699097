import React from "react";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  MuiThemeProvider,
  Typography,
} from "@material-ui/core";
import MailOutline from "@material-ui/icons/MailOutline";
import theme, { oo } from "../../element/theme";
import NewsletterLegalText from "./NewsletterLegalText";
import isEmail from "validator/es/lib/isEmail";
import { muiDarkTheme } from "./muiDarkTheme";


const heading = "Newsletter ";
const checkBoxGdprLabel = " I would like to subscribe to receive updates and promotional offers from Oberion.";
const confirmEmail = "Email is not valid";
const confirmCheckboxHint = "Please confirm that you want to subscribe by clicking the checkbox.";


class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isEmailValid: false,
      isCheckboxChecked: false,
      isSubscribeClicked: false,
    };
  }

  validateCheckbox = () => {
    this.setState({ isCheckboxChecked: !this.state.isCheckboxChecked });
  };

  validateEmail = (event) => {
    this.setState({ isEmailValid: isEmail(event.target.value) });
  };

  isValidForm = () => {
    if (!this.state.isEmailValid) return false;
    if (!this.state.isCheckboxChecked) return false;
    return true;
  };

  handleSubmit = (e) => {
    if (!this.isValidForm()) {
      e.preventDefault();
      this.setState({ isSubscribeClicked: true });
    }
  };

  render() {
    let feedbackCheckbox = null;
    let feedbackEmail = null;
    let GdprCheckbox = (
      <FormControlLabelSt label={checkBoxGdprLabel}
                          required
                          control={(<Checkbox checked={this.state.isCheckboxChecked}
                                              onChange={this.validateCheckbox}
                                              color="secondary" />)} />
    );

    // show checkbox error
    if ((this.state.isSubscribeClicked === true) && (this.state.isCheckboxChecked === false)) {
      feedbackCheckbox = (<Typography color="error">{confirmCheckboxHint}</Typography>);
      GdprCheckbox = (
        <BorderFormControlLabel label={checkBoxGdprLabel}
                                required
                                control={(<Checkbox checked={this.state.isCheckboxChecked}
                                                    onChange={this.validateCheckbox}
                                                    color="secondary" />)} />
      );
    }
    // show invalid email error
    if ((this.state.isSubscribeClicked === true) && (!this.state.isEmailValid)) {
      feedbackEmail = (<Typography color="error">{confirmEmail}</Typography>);
    }

    /**
     * RETURN
     */
    return (
      <MuiThemeProvider theme={muiDarkTheme}>
        <FormSt action={process.env.GATSBY_MAILCHIMP_ENDPOINT}
                method="POST"
                onSubmit={this.handleSubmit}
                id="newsletter">
          <Typography component="h2" variant="h3" paragraph style={{ fontWeight: 300 }}>
            {heading}
            <BigMailOutline color="secondary" />
          </Typography>

          <StyledGrid container spacing={3} justify="center" alignItems="center">
            <Grid item sm={12} md={6}>

              {GdprCheckbox}

              <TextFieldButtonDiv>
                <LabelSt htmlFor="MERGE0">
                  <InputSt
                    placeholder="Email *"
                    type="email"
                    name="EMAIL"
                    id="MERGE0"
                    required
                    onBlur={this.validateEmail}
                    onChange={this.validateEmail}
                    defaultValue={this.state.email}
                    color="secondary"
                    error={(this.state.isSubscribeClicked === true) && (!this.state.isEmailValid)}
                  />
                </LabelSt>
                <SubscribeButton color="secondary"
                                 variant="outlined"
                                 type="submit"
                                 value="Subscribe"
                                 id="mc-embedded-subscribe"
                                 size="large">
                  Subscribe
                </SubscribeButton>
              </TextFieldButtonDiv>

              {feedbackCheckbox}
              {feedbackEmail}
            </Grid>

            <Grid item sm={12} md={6}>
              <NewsletterLegalText />
            </Grid>
          </StyledGrid>
          <div style={{ position: "absolute", left: "-5000px" }} aria-hidden='true'
               aria-label="Please leave the following fields empty">
            <label htmlFor="b_email">Email: </label>
            <input type="email" name="b_email" tabIndex="-1" value="" onChange={() => null}
                   placeholder="youremail@gmail.com" id="b_email" />
          </div>
        </FormSt>
      </MuiThemeProvider>
    );
  }
}

export default Newsletter;

const FormSt = styled.form`
  background-color: ${oo.oberionBlueBackground};
  color: ${oo.textColorOnDark};
  text-align: center;
  max-width: ${oo.contentWidth}rem;
  margin: 0 auto;
  padding: ${oo.verticalSpacing}rem 1rem ${oo.verticalSpacing * 1.25}rem 1rem;
`;
const TextFieldButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 4.5rem;
`;
const FormControlLabelSt = styled(FormControlLabel)`
  max-width: 26rem;
  text-align: left;
`;
const BorderFormControlLabel = styled(FormControlLabelSt)`
  border: 1px solid ${theme.palette.error.main};
`;
const SubscribeButton = styled(Button)`
  margin-left: 1.5rem;
`;
const BigMailOutline = styled(MailOutline)`
  margin-bottom: -0.5rem;
  height: 2.5rem;
  width: 2.5rem;
`;
const InputSt = styled(Input)`
  width: 100%;
  max-width: 16rem;
`;
const LabelSt = styled.label`
  width: 100%;
  max-width: 16rem;
`;
const StyledGrid = styled(Grid)`
  margin-top: 1rem;
`;