import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const createDescriptionTag = description => {
  if (description == null) return null;
  else {
    return (
      [
        <meta name="description" content={description} key="k1" />,
        <meta name="twitter:description" content={description} key="k2" />,
        <meta property="og:description" content={description} key="k3" />,
      ]
    );
  }
};
const createCanonicalTag = canonical => {
  if (canonical == null) return null;
  else {
    return (
      [
        <link rel="canonical" href={canonical} key="k1" />,
        <meta property="og:url" content={canonical} key="k2" />,
      ]
    );
  }
};
const createImageTag = image => {
  if (image == null) return null;
  else {
    return (
      [
        <meta property="og:image" content={image} key="k1" />,
        <meta name="twitter:image" content={image} key="k2" />,
      ]
    );
  }
};
const createTwitterCardTag = twitterCard => {
  return (twitterCard == null) ? null : <meta name="twitter:card" content={twitterCard} />;
};
const createTwitterSiteTag = twitterSite => {
  return (twitterSite == null) ? null : <meta name="twitter:site" content={twitterSite} />;
};
const createTwitterCreatorTag = twitterCreator => {
  return (twitterCreator == null) ? null : <meta name="twitter:creator" content={twitterCreator} />;
};
const createOgTypeTag = ogType => {
  return (ogType == null) ? null : <meta property="og:type" content={ogType} />;
};
const createOgSiteNameTag = ogSiteName => {
  return (ogSiteName == null) ? null : <meta property="og:site_name" content={ogSiteName} />;
};

/**
 * Provides meta tags for each page
 * @param title           max 70 characters
 * @param description     max 155 characters
 * @param twitter:image   min 144x144px, max 1MB, no SVG support
 */
const SEO = ({
               title, description, canonical, image,
               twitterCard, twitterSite, twitterCreator,
               ogType, ogSiteName,
               // fbAdmins, fbAppid,
             }) => {
  const descriptionTag = createDescriptionTag(description);
  const canonicalTag = createCanonicalTag(canonical);
  const imageTag = createImageTag(image);
  const twitterCardTag = createTwitterCardTag(twitterCard);
  const twitterSiteTag = createTwitterSiteTag(twitterSite);
  const twitterCreatorTag = createTwitterCreatorTag(twitterCreator);
  const ogTypeTag = createOgTypeTag(ogType);
  const ogSiteNameTag = createOgSiteNameTag(ogSiteName);
  // const fbAdminsTag = createFbAdminsTag(fbAdmins);
  // const fbAppidTag = createFbAppidTag(fbAppid);


  return (
    <Helmet title={title}>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />
      {descriptionTag}
      {canonicalTag}
      {imageTag}
      {twitterCardTag}
      {twitterSiteTag}
      {twitterCreatorTag}
      {ogTypeTag}
      {ogSiteNameTag}
      {/*<meta property="fb:admins" content="Facebook numeric ID" />*/}
      {/*<meta property="fb:app_id" content="105386699540688">*/}
    </Helmet>
  );
};

export default SEO;
SEO.defaultProps = {
  title: "Oberion",
  description: null,
  canonical: null,
  image: "/logo.OberionIO.png",
  twitterCard: "summary",
  twitterSite: "@OberionIO",
  twitterCreator: "@ThomasJanUta",
  ogType: "website",
  ogSiteName: "Oberion",
  // fbAdminsTag: "",
  // fbAppidTag: "",
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonical: PropTypes.string,
  image: PropTypes.string,
  twitterCard: PropTypes.string,
  twitterSite: PropTypes.string,
  twitterCreator: PropTypes.string,
  ogType: PropTypes.string,
  ogSiteName: PropTypes.string,
  // fbAdminsTag: PropTypes.string,
  // fbAppidTag: PropTypes.string,
};
